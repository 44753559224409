
import React, { useState, useEffect, useRef } from 'react';
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import PlayerPiece from './PlayerPiece';
import { BASE_POSITIONS, HOME_ENTRANCE, HOME_POSITIONS, PLAYERS, SAFE_POSITIONS, START_POSITIONS, STATE, TURNING_POINTS } from './constants';
import cssp from "../css/LudoBorad.css"
import { useParams, useLocation, useHistory } from 'react-router-dom';
import socket from './socketconnection';
import { fetchData } from '../../RaectRedux/ActionApiOfData';
import { useDispatch } from 'react-redux';


const LudoBoard = () => {
    const [isRolling, setIsRolling] = useState(false);
    const [finalTransform, setFinalTransform] = useState("");
    const dispatch = useDispatch();
    let [myturn, setMyturn] = useState(true);
    let [random, setRandom] = useState(Math.random())
    let [initialTime, setInitialTime] = useState(30);
    let [, setRender] = useState(0);
    let [isPeiceClicked, setIsPeiceClicked] = useState(false);
    let [result, setResult] = useState(null);
    let [missedTurn, setMissedTurn] = useState({ P1: 0, P2: 0 })
    let popAudio = new Audio('/Images/tokenPop.mp3');
    let killAudio = new Audio('/Images/kill.mp3');
    let homeAudio = new Audio('/Images/atHome.mp3');
    let safeAudio = new Audio('/Images/safe.mp3');
    const faceToRotation = {
        1: "rotateX(0deg) rotateY(0deg)", // Front face
        2: "rotateX(-90deg) rotateY(0deg)", // Top face
        3: "rotateX(0deg) rotateY(90deg)", // Right face
        4: "rotateX(0deg) rotateY(-90deg)", // Left face
        5: "rotateX(90deg) rotateY(0deg)", // Bottom face
        6: "rotateX(180deg) rotateY(0deg)", // Back face
    };
    // let [positionCount, setPositionCount] = useState({
    //     P1: [1, 1, 1, 1],
    //     P2: [1, 1, 1, 1]
    // })
    // let autoIncrement = useRef(false);
    // let autoDiceRoll = useRef(true);
    useEffect(() => {
        const changeTurn = () => {
            setMyturn((prev) => !prev);
            // autoDiceRoll.current = true;
        }
        socket.on('offTurn', () => {
            setMyturn(false);
            //    autoDiceRoll.current = true;
        });
        socket.on('changeTurn', changeTurn);
        return () => {
            socket.off("offTurn");
            socket.off("changeTurn");
        }
    }, [])
    const Location = useLocation();
    const history = useHistory();
    const { game, gameData } = Location.state.state;

    useEffect(() => {
        const handleTouchStart = (event) => {
            if (window.scrollY === 0 && event.touches.length > 0) {
                const touchStartY = event.touches[0].clientY;
                event.target.touchStartY = touchStartY;
            }
        };

        const handleTouchMove = (event) => {
            if (window.scrollY === 0 && event.target.touchStartY !== undefined) {
                const touchCurrentY = event.touches[0].clientY;

                if (touchCurrentY > event.target.touchStartY) {
                    event.preventDefault();
                }
            }
        };

        document.addEventListener('touchstart', handleTouchStart, { passive: true });
        document.addEventListener('touchmove', handleTouchMove, { passive: false });

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);


    function checkForposition(position) {
        let count = 0;
        currentPositions.current['P1'].forEach((val) => {
            if (val == position) {
                count++;
            }
        })
        currentPositions.current['P2'].forEach((val) => {
            if (val == position) {
                count++;
            }
        })
        return count;
    }

    useEffect(() => {
        socket.on('result', (player) => {
            console.log('le bhai sb khtm ho gya , m to tut gya', player);
            dispatch(fetchData());
            const Player = localStorage.getItem("playerId");
            setTimeout(() => {
                if (player == Player) {
                    setResult('Win');
                } else {
                    setResult('Loss');
                }
            }, 500);
            setTimeout(() => {
                history.push('/HomePagegame/Ludogame', { state: { game } });
            }, 4000)

        })
        return () => {
            socket.off('result');
        }
    }, [])


    useEffect(() => {
        if (gameData) {
            let position = {};
            console.log(gameData, 'gamedata');
            if (gameData.player == 'P1') {
                position.P1 = gameData.gameState.position['P1'];
                position.P2 = gameData.gameState.position['P2'].map((val) => {
                    if (val >= 100) {
                        return val + 100;
                    }
                    else if (val >= 26) {
                        return val - 26;
                    }
                    else {
                        return val + 26;
                    }
                })
            }
            else {
                position.P1 = gameData.gameState.position['P2'];
                position.P2 = gameData.gameState.position['P1'].map((val) => {
                    if (val >= 100) {
                        return val + 100;
                    }
                    else if (val >= 26) {
                        return val - 26;
                    }
                    else {
                        return val + 26
                    }
                })
            }
            currentPositions.current = position;
            diceValue.current = gameData.gameState.dice
            setFinalTransform(faceToRotation[diceValue.current]);
            setRender(prev => (prev + 1));
            if ((gameData.player == 'P1' && gameData.gameState.turn == 0) || (gameData.player == 'P2' && gameData.gameState.turn == 1)) {
                setMyturn(true);
            }
            else {
                setMyturn(false);
            }
            if (gameData.diceTimer == 30) {
                setState({ ...STATE }.DICE_ROLLED);
                setInitialTime(gameData.peiceTimer)
                setRandom(Math.random())
            }
            if (gameData.peiceTimer == 30) {
                setIsPeiceClicked(true);
                setInitialTime(gameData.diceTimer)
                setRandom(Math.random())
            }
        }
    }, [])

    useEffect(() => {
        console.log(initialTime, 'initialTime')
        // Calculate the border angle based on the timer

    }, [initialTime]);


    useEffect(() => {
        const handleBack = (event) => {
            event.preventDefault();
            history.go(-1);
        };

        window.addEventListener("popstate", handleBack);

        return () => {
            window.removeEventListener("popstate", handleBack);
        };
    }, [history]);

    const { gameId } = useParams();
    let currentPositions = useRef({
        P1: [...BASE_POSITIONS.P1],
        P2: [...BASE_POSITIONS.P2]
    })
    const diceValue = useRef(1);
    const [turn, setTurn] = useState(0)
    const [state, setState] = useState({ ...STATE }.DICE_NOT_ROLLED);
    const [isSpinning, setIsSpinning] = useState(false);

    const resetGame = () => {
        currentPositions.current = {
            P1: [...BASE_POSITIONS.P1],
            P2: [...BASE_POSITIONS.P2]
        }

        setTurn(0);
        setState(STATE.DICE_NOT_ROLLED);
    };

    const onDiceClick = () => {
        // autoDiceRoll.current = false;
        setState({ ...STATE }.DICE_ROLLED);
        //console.log('dice clicked by player step1')
        if (!myturn) {
            //console.log('step1 not myturn')
            return;
        }

        const player = localStorage.getItem("playerId")
        // const roll = 1 + Math.floor(Math.random() * 6);
        //console.log('step 1  -- myturn')
        socket.emit("Dice-Value", { gameId, player })
    };

    useEffect(() => {
        socket.on("DiceValueClient", (msg) => {
            //console.log('dice click event received from server',msg);
            setState({ ...STATE }.DICE_ROLLED);
            setInitialTime(30);
            setRandom(Math.random())
            setIsSpinning(true);
            setIsPeiceClicked(false);
            // autoIncrement.current = true;
            const audio = new Audio('/Images/dice.ogg');
            audio.play();

            //console.log(msg,'diceValue')

            // setTimeout(() => {
            //     // setDiceValue(msg.roll);
            //     diceValue.current = msg.roll;
            //     setIsSpinning(false);
            //     checkForEligiblePieces(msg.player);
            //     // AutoIncrement(msg.player);
            // }, 800);

            setIsRolling(true);

            const resultFace = msg.roll;
            const resultRotation = faceToRotation[resultFace];

            setTimeout(() => {
                diceValue.current = msg.roll;
                setIsRolling(false);
                setFinalTransform(resultRotation);
                checkForEligiblePieces(msg.player)
            }, 800); // Roll duration

        })
        socket.on("handleClickPiececClient", ({ player, piece, gameState, term, missed }) => {
            console.log(term, 'step0', gameState);
            //    autoIncrement.current = false;
            const user = localStorage.getItem("playerId");
            let Player = user === player ? 'P1' : 'P2';
            let tempPosition;
            if (Player === 'P1') {
                tempPosition = gameState.position[term];
                console.log(tempPosition, 'step1', term)
                setIsPeiceClicked(true);

            }
            else {
                tempPosition = gameState.position[term];
                let newTemp = tempPosition.map((val) => {
                    if (val >= 100) {
                        return val + 100;
                    }
                    else if (val >= 26) {
                        return val - 26;
                    }
                    else {
                        return val + 26
                    }
                })
                tempPosition = [...newTemp];
                console.log(tempPosition, 'step2', term)
            }

            if ((Player === 'P1' && term === 'P1') || (Player === 'P2' && term === 'P2')) {
                setMissedTurn({ P1: missed.P1, P2: missed.P2 })
            }
            else {
                setMissedTurn({ P1: missed.P2, P2: missed.P1 })
            }

            // let tempPosition = term=='P1'?
            //console.log('server se peice click ka event aa gya h',Player,piece);
            const currentPosition = currentPositions.current[Player][piece];
            console.log(tempPosition, 'tempPosition')


            if (getEligiblePieces(Player).includes(piece)) {
                //console.log('server se aane ke bad peice to thi lag rha h')
                // if (BASE_POSITIONS[Player].includes(currentPosition)) {
                //     //console.log('peice home wala')
                //     setPiecePosition(Player, piece, START_POSITIONS[Player]);
                //     // autoDiceRoll.current = true;
                setState(STATE.DICE_NOT_ROLLED);


                // }
                if ((tempPosition[piece] == 0 && Player == 'P1') || (tempPosition[piece] == 26 && Player == 'P2')) {
                    popAudio.play();
                    setPiecePosition(Player, piece, tempPosition[piece])
                }


                else {
                    //console.log('home wala nhi h chalo aage')
                    movePiece(Player, piece, gameState.dice);

                }

                setInitialTime(30);
                setRandom(Math.random())

            }

        })
        return () => {
            socket.off("DiceValueClient");
            socket.off("handleClickPiececClient");
        };


    }, []);

    const setPiecePosition = (player, piece, newPosition) => {
        console.log(player, piece, newPosition, 'inside set position')
        const updatedPositions = {
            ...currentPositions.current,
            [player]: currentPositions.current[player].map((pos, index) =>
                index === piece ? newPosition : pos
            )
        };
        // setCurrentPositions(updatedPositions);
        currentPositions.current = updatedPositions;
        const playerId = localStorage.getItem('playerId');

        setRender(prev => (prev + 1));
        if (player === 'P1') {
            socket.emit("GameState", { gameId, currentPositions: updatedPositions, turn: myturn ? 0 : 1, diceValue: diceValue.current, playerId });
        }
    };
    const movePiece = async (player, piece, moveBy) => {
        console.log('peice ko move krte h')
        let movesRemaining = moveBy;
        const moveInterval = 300 // Interval for updating position

        const moveIntervalId = setInterval(async () => {

            if (movesRemaining > 0) {
                console.log('inside movepeice')
                const currentPosition = currentPositions.current[player][piece];
                if (currentPosition === TURNING_POINTS[player]) {
                    if (game === 'Owner') {
                        setPiecePosition(player, piece, HOME_POSITIONS[player]);
                        setState(STATE.DICE_NOT_ROLLED);
                        clearInterval(moveIntervalId);
                    }
                    else {
                        setPiecePosition(player, piece, HOME_ENTRANCE[player][0]);
                        popAudio.play();
                    }
                    // return HOME_ENTRANCE[player][0];
                }
                else if (currentPosition === 51) {
                    setPiecePosition(player, piece, 0);
                    popAudio.play();
                }

                else {
                    if ((currentPosition + 1) === HOME_POSITIONS[player]) {
                        homeAudio.play();
                    }
                    else if (SAFE_POSITIONS.includes(currentPosition + 1) && movesRemaining===1) {
                        safeAudio.play();
                    }
                    else {
                        popAudio.play();
                    }
                    setPiecePosition(player, piece, currentPosition + 1);

                }
                movesRemaining--;
            } else {
                clearInterval(moveIntervalId);

                // if (hasPlayerWon(player)) {
                //     alert(`Player: ${player} has won!`);
                //     resetGame();
                //     return;
                // }

                const isKill = checkForKill(player, piece);
                if (!isKill && diceValue.current != 6) {
                    // incrementTurn();
                    if (player === 'P1') {
                        socket.emit('changeTurnClient', gameId);
                    }
                    setState(STATE.DICE_NOT_ROLLED);

                }

                else {
                    if (player === 'P1') {
                        // autoDiceRoll.current = true;
                    }
                }
                setState(STATE.DICE_NOT_ROLLED);
            }
        }, moveInterval);
    };

    // useEffect(() => {
    //     let autoInterval;

    //     if (autoIncrement.current) {
    //         autoInterval = setTimeout(() => {
    //             if (myturn) {
    //                 const newPiece = getEligiblePieces('P1');
    //                 if (newPiece.length > 0) {
    //                     handlePieceClick('P1', newPiece[0]);
    //                 } else {
    //                     //console.log("No eligible pieces to move.");
    //                 }
    //             }
    //         }, 30000);
    //     }

    //     else{
    //         clearTimeout(autoInterval);
    //     }

    //     return () => {
    //         if (autoInterval) clearTimeout(autoInterval);
    //     };
    // }, [autoIncrement.current]);


    // useEffect(()=>{
    //  let diceTimeout;
    //  if(autoDiceRoll.current && myturn){
    //     diceTimeout = setTimeout(() => {
    //         onDiceClick();
    //     },30000);
    //  }
    //  else{
    //     if (diceTimeout) clearTimeout(diceTimeout);
    //  }
    //  return ()=>{
    //     if (diceTimeout) clearTimeout(diceTimeout);
    //  }
    // },[autoDiceRoll.current,myturn])

    const checkForKill = (player, piece) => {
        const currentPosition = currentPositions.current[player][piece];
        const opponent = player === 'P1' ? 'P2' : 'P1';
        let kill = false;
        [0, 1, 2, 3].forEach(opponentPiece => {
            const opponentPosition = currentPositions.current[opponent][opponentPiece];
            if (currentPosition === opponentPosition && !SAFE_POSITIONS.includes(currentPosition)) {
                killAudio.play();
                setPiecePosition(opponent, opponentPiece, BASE_POSITIONS[opponent][opponentPiece]);
                kill = true;
            }
        });
        return kill;
    };

    const hasPlayerWon = (player) => {
        return [0, 1, 2, 3].every(piece => currentPositions.current[player][piece] === HOME_POSITIONS[player]);
    };

    const checkForEligiblePieces = (val) => {
        //console.log('check for eligiblepeice')
        const user = localStorage.getItem('playerId')
        const player = val === user ? "P1" : "P2";
        const eligiblePieces = getEligiblePieces(player);
        //console.log('eligible peice for player ',player)
        if (eligiblePieces.length < 1) {
            if (player === 'P1') {
                //console.log('peice available nhi h to turn change kr do');
                socket.emit("GameState", { gameId, currentPositions: currentPositions.current, turn: myturn ? 1 : 0, diceValue: diceValue.current, playerId: user, status: true });
                socket.emit('changeTurnClient', gameId);

            }

            setState(STATE.DICE_NOT_ROLLED);
            // incrementTurn();
        }
    };

    const getEligiblePieces = (player) => {
        return [0, 1, 2, 3].filter(piece => {
            const currentPosition = currentPositions.current[player][piece];
            if (currentPosition === HOME_POSITIONS[player]) return false;
            if (BASE_POSITIONS[player].includes(currentPosition) && diceValue.current !== 6) return false;
            if (HOME_ENTRANCE[player].includes(currentPosition) && diceValue.current > HOME_POSITIONS[player] - currentPosition) return false;
            return true;
        });
    };

    const handlePieceClick = (player, piece, e = null) => {
        //console.log('peice clicked by player ',player)
        // autoDiceRoll.current = false;
        if (!myturn || isPeiceClicked || !getEligiblePieces(player).includes(piece)) {
            console.log('mera turn nhi h ya fir peice already click ho chuki h ,', myturn, isPeiceClicked)
            return;
        }
        //console.log('peice click thik ja rha h')
        if ((myturn && player == "P1") && getEligiblePieces(player).length > 0) {
            setIsPeiceClicked(true);
            console.log('peice to shi h bhai')
            const user = localStorage.getItem("playerId");
            socket.emit("handleClickPiece", { gameId, player: user, piece, turn })
        }
        else {
            //console.log('lagta h glt peice click kr di h')
        }

    };

    return (
        <>
            <div className="leftContainer leftContainer-Ludo">
                <div className={`ludomain ${result ? 'blur' : ''}`}>
                    <div className="sound"><img src="/image/images/soundon.png" alt="" srcset="" /></div>

                    <div className="ludo-container">
                        <div className={`ludo ${game}`}>
                            <div className="player-pieces">
                                {PLAYERS.map(player =>
                                    currentPositions.current[player].map((position, index) => (
                                        <PlayerPiece
                                            key={`${player}-${index}`}
                                            playerId={player}
                                            pieceId={index}
                                            position={position}
                                            onClick={handlePieceClick}
                                        />

                                    ))
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="footer">
                        <div className="footer-avtar">
                            <div className="img-left">
                                <div className={`P1 ${myturn && !result ? 'animation bj' : ''}`}
                                    key={random}
                                    style={{
                                        "--newvar": `${360 - (initialTime / 30) * 360}deg`,
                                        animationDuration: `${initialTime}s`, // Adjust duration dynamically
                                    }}
                                >
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnveTKwxve5MH7HXXKE-3iT7_ihOpC0CxLGg&s" alt="" srcset="" />
                                </div>
                            </div>
                            <div className={`P2 ${!myturn && !result ? 'animation bj' : ''}`}
                                key={random}
                                style={{
                                    "--newvar": `${360 - (initialTime / 30) * 360}deg`,
                                    animationDuration: `${initialTime}s`, // Adjust duration dynamically
                                }}
                            >
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnveTKwxve5MH7HXXKE-3iT7_ihOpC0CxLGg&s" alt="" srcset="" />
                            </div>
                        </div>
                        {myturn ? <div className={`arrow ${state !== STATE.DICE_ROLLED ? 'shrink' : 'dis'}`}>
                            <img src="/image/images/arrow.png" alt="Arrow" />
                        </div> : ""}

                        <div className={`dice-main ${state === STATE.DICE_ROLLED ? 'disabled' : ''}`}>
                            {/* <img
                                src={`/image/images/Dice${diceValue.current}.png`}
                                className={`dice ${isSpinning ? 'spin' : ''} btn btn-dice`}
                                alt="This is zero"
                                onClick={state !== STATE.DICE_ROLLED ? onDiceClick : undefined}
                                id="dice-btn"
                            /> */}
                            <div className="dice-wrapper"
                                onClick={state !== STATE.DICE_ROLLED ? onDiceClick : undefined}
                            >
                                <div
                                    className={`dice ${isRolling ? "rolling" : ""}`}
                                    style={{
                                        transform: isRolling
                                            ? `rotateX(${Math.random() * 360}deg) rotateY(${Math.random() * 360}deg)`
                                            : finalTransform,
                                    }}
                                >
                                    <div className="face front">
                                        <img src="/image/images/Dice1.png" alt="1" />
                                    </div>
                                    <div className="face back">
                                        <img src="/image/images/Dice6.png" alt="6" />
                                    </div>
                                    <div className="face right1">
                                        <img src="/image/images/Dice4.png" alt="4" />
                                    </div>
                                    <div className="face left1">
                                        <img src="/image/images/Dice3.png" alt="3" />
                                    </div>
                                    <div className="face top">
                                        <img src="/image/images/Dice2.png" alt="2" />
                                    </div>
                                    <div className="face bottom">
                                        <img src="/image/images/Dice5.png" alt="5" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-last">
                            <div className="left">
                                <div className="left-upper">
                                    <div className="left-pieceimg">
                                        <img src="/image/images/P1.png" alt="" srcset="" />

                                    </div>
                                    <div className="coin-five">
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <div key={index} className="coin" style={{ backgroundColor: missedTurn.P1 >= index + 1 ? 'red' : 'green' }}></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="nameBox">
                                    <div className="name-heading">
                                        Name
                                    </div>
                                    <hr />
                                    <div className="Name">yte-ieh-idj</div>
                                </div>
                            </div>
                            <div className="right">

                                <div className="nameBox">
                                    <div className="name-heading">
                                        Name
                                    </div>
                                    <hr />
                                    <div className="Name">yte-ieh-idj</div>
                                </div>


                                <div className="right-upper">
                                    <div className="left-pieceimg">
                                        <img src="/image/images/P1.png" alt="" srcset="" />
                                    </div>
                                    <div className="coin-five">
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <div key={index} className="coin" style={{ backgroundColor: missedTurn.P2 >= index + 1 ? 'red' : 'green' }}></div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                {result ?
                    <div className='result'>
                        <div className='gameover'>
                            Game Over
                        </div>
                        <div className='result_line'>
                            {result === 'Win' ? 'You Won !' : 'You Lost , Better luck next time !'}
                        </div>
                        <div>
                        </div>
                        {result === 'Win' ?
                            <img src='/image/images/win.gif' alt='Congratulations' />
                            : ''
                        }

                    </div>
                    : ''
                }

            </div>

        </>
    );
};

export default LudoBoard;
