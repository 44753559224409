
import React, { useState, useEffect, useRef } from 'react';
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import PlayerPiece from './PlayerPiece';
import { BASE_POSITIONS, HOME_ENTRANCE, HOME_POSITIONS, SAFE_POSITIONS, START_POSITIONS, STATE, TURNING_POINTS } from './constants';
import cssp from "../css/LudoBorad.css"
import { useParams, useLocation, useHistory } from 'react-router-dom';
import socket from './socketconnection1';
import { fetchData } from '../../RaectRedux/ActionApiOfData';
import { useDispatch } from 'react-redux';


const LudoBoard = () => {
    const PLAYERS = ['P1', 'P2', 'P3', 'P4']
    const [isRolling, setIsRolling] = useState(false);
    const [finalTransform, setFinalTransform] = useState("");
    const [count, setCount] = useState({ count: 22, points: 0 });
    const dispatch = useDispatch();
    let [myturn, setMyturn] = useState(true);
    let [random, setRandom] = useState(Math.random())
    let [initialTime, setInitialTime] = useState(30);
    let [, setRender] = useState(0);
    let [isPeiceClicked, setIsPeiceClicked] = useState(true);
    let [result, setResult] = useState(false);
    let [missedTurn, setMissedTurn] = useState(0);

    let popAudio = new Audio('/Images/tokenPop.mp3');
    let killAudio = new Audio('/Images/kill.mp3');
    let homeAudio = new Audio('/Images/atHome.mp3');
    let safeAudio = new Audio('/Images/safe.mp3');
    const faceToRotation = {
        1: "rotateX(0deg) rotateY(0deg)",
        2: "rotateX(-90deg) rotateY(0deg)",
        3: "rotateX(0deg) rotateY(90deg)",
        4: "rotateX(0deg) rotateY(-90deg)",
        5: "rotateX(90deg) rotateY(0deg)",
        6: "rotateX(180deg) rotateY(0deg)",
    };
    useEffect(() => {
        const changeTurn = () => {
            setMyturn((prev) => !prev);
            // autoDiceRoll.current = true;
        }
        socket.on('offTurn', () => {
            setMyturn(false);
            //    autoDiceRoll.current = true;
        });
        socket.on('changeTurn', changeTurn);
        socket.on('Complete', () => {

            console.log('hello game completed')
            setMyturn(false);
            setResult(true);

            setTimeout(() => {
                history.push('/yodhaContaxt');
            }, 3000);

        })
        return () => {
            socket.off("offTurn");
            socket.off("changeTurn");
        }
    }, [])
    const Location = useLocation();
    const history = useHistory();
    // const { game, gameData } = Location.state.state;


    useEffect(() => {
        const handleTouchStart = (event) => {
            if (window.scrollY === 0 && event.touches.length > 0) {
                const touchStartY = event.touches[0].clientY;
                event.target.touchStartY = touchStartY;
            }
        };

        const handleTouchMove = (event) => {
            if (window.scrollY === 0 && event.target.touchStartY !== undefined) {
                const touchCurrentY = event.touches[0].clientY;
                if (touchCurrentY > event.target.touchStartY) {
                    event.preventDefault();
                }
            }
        };

        document.addEventListener('touchstart', handleTouchStart, { passive: true });
        document.addEventListener('touchmove', handleTouchMove, { passive: false });

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, []);


    function checkForposition(position) {
        let count = 0;
        currentPositions.current['P1'].forEach((val) => {
            if (val == position) {
                count++;
            }
        })
        currentPositions.current['P2'].forEach((val) => {
            if (val == position) {
                count++;
            }
        })
        return count;
    }

    useEffect(() => {
        const handleBack = (event) => {
            event.preventDefault();
            history.go(-1);
        };

        window.addEventListener("popstate", handleBack);

        return () => {
            window.removeEventListener("popstate", handleBack);
        };
    }, [history]);

    const { gameId } = useParams();
    const { position, id, rejoin, diceTime, peiceTime, valueDice, missed, remaining, score } = Location.state.state;
    console.log(position)
    let currentPositions = useRef({
        P1: rejoin ? position['P1'] : [0, 0, 0, 0],
        P2: position.P2,
        P3: position.P3,
        P4: position.P4,
    })
    const diceValue = useRef(1);
    const [turn, setTurn] = useState(0)
    const [state, setState] = useState({ ...STATE }.DICE_NOT_ROLLED);
    const [isSpinning, setIsSpinning] = useState(false);

    useState(() => {
        if (diceTime == 30) {
            setState({ ...STATE }.DICE_ROLLED);
            setInitialTime(peiceTime)
            setRandom(Math.random())
        }
        if (peiceTime == 30) {
            setIsPeiceClicked(true);
            setInitialTime(diceTime)
            setRandom(Math.random())
        }
        diceValue.current = valueDice;
        setFinalTransform(faceToRotation[diceValue.current]);
        setMissedTurn(missed);
        setCount({ count: remaining, points: score });
    }, [])
    const resetGame = () => {
        currentPositions.current = {
            P1: [0, 0, 0, 0]
        }

        setTurn(0);
        setState(STATE.DICE_NOT_ROLLED);
    };

    const onDiceClick = () => {
        // autoDiceRoll.current = false;
        setState({ ...STATE }.DICE_ROLLED);
        if (!myturn) {
            return;
        }

        const player = localStorage.getItem("playerId")
        socket.emit("Dice-Value", { gameId, player, id })
    };

    useEffect(() => {
        socket.on("DiceValueClient", (msg) => {
            setState({ ...STATE }.DICE_ROLLED);
            setInitialTime(30);
            setRandom(Math.random())
            setIsSpinning(true);

            // autoIncrement.current = true;
            const audio = new Audio('/Images/dice.ogg');
            audio.play();

            setIsRolling(true);

            const resultFace = msg.roll;
            const resultRotation = faceToRotation[resultFace];

            setTimeout(() => {
                diceValue.current = msg.roll;
                setIsRolling(false);
                setFinalTransform(resultRotation);
                checkForEligiblePieces(msg.player)
            }, 800); // Roll duration

        })
        socket.on("handleClickPiececClient", ({ piece, gameState, dice, count, points, missed }) => {
            const user = localStorage.getItem("playerId");
            let Player = 'P1';
            let tempPosition = gameState;
            setIsPeiceClicked(true);
            setCount({ count, points });
            setMissedTurn(missed);

            if (getEligiblePieces('P1').includes(piece)) {
                setState(STATE.DICE_NOT_ROLLED);
                if (tempPosition[piece] == 0 && Player == 'P1') {
                    setPiecePosition('P1', piece, tempPosition[piece]);
                    popAudio.play();
                }
                else {
                    movePiece('P1', piece, dice);
                }
                setInitialTime(30);
                setRandom(Math.random())
            }
        })
        return () => {
            socket.off("DiceValueClient");
            socket.off("handleClickPiececClient");
        };


    }, []);

    const setPiecePosition = (player, piece, newPosition) => {
        const updatedPositions = {
            ...currentPositions.current,
            [player]: currentPositions.current[player].map((pos, index) =>
                index === piece ? newPosition : pos
            )
        };
        // setCurrentPositions(updatedPositions);
        currentPositions.current = updatedPositions;
        const playerId = localStorage.getItem('playerId');

        setRender(prev => (prev + 1));
        if (player === 'P1') {
            socket.emit("GameState", { gameId, currentPositions: updatedPositions, turn: myturn ? 0 : 1, diceValue: diceValue.current, playerId });
        }
    };
    const movePiece = async (player, piece, moveBy) => {
        let movesRemaining = moveBy;
        const moveInterval = 300 // Interval for updating position

        const moveIntervalId = setInterval(async () => {

            if (movesRemaining > 0) {
                const currentPosition = currentPositions.current[player][piece];
                if (currentPosition === TURNING_POINTS[player]) {

                    setPiecePosition(player, piece, HOME_ENTRANCE[player][0]);
                    popAudio.play();

                    // return HOME_ENTRANCE[player][0];
                }
                else if (currentPosition === 51) {
                    setPiecePosition(player, piece, 0);
                    popAudio.play();
                }

                else {
                    if ((currentPosition + 1) === HOME_POSITIONS[player]) {
                        homeAudio.play();
                    }
                    else if (SAFE_POSITIONS.includes(currentPosition + 1) && movesRemaining===1) {
                        safeAudio.play();
                    }
                    else {
                        popAudio.play();
                    }

                    setPiecePosition(player, piece, currentPosition + 1);
                }
                movesRemaining--;
            } else {
                clearInterval(moveIntervalId);


                const isKill = checkForKill(player, piece);
                if (!isKill && diceValue.current != 6) {
                    if (player === 'P1') {
                        socket.emit('changeTurnClient', gameId);
                    }
                    setState(STATE.DICE_NOT_ROLLED);

                }

                // else {
                //     if (player === 'P1') {
                //         // autoDiceRoll.current = true;
                //     }
                // }
                setState(STATE.DICE_NOT_ROLLED);
            }
        }, moveInterval);
    };

    // useEffect(() => {
    //     let autoInterval;

    //     if (autoIncrement.current) {
    //         autoInterval = setTimeout(() => {
    //             if (myturn) {
    //                 const newPiece = getEligiblePieces('P1');
    //                 if (newPiece.length > 0) {
    //                     handlePieceClick('P1', newPiece[0]);
    //                 } else {
    //                     ////console.log("No eligible pieces to move.");
    //                 }
    //             }
    //         }, 30000);
    //     }

    //     else{
    //         clearTimeout(autoInterval);
    //     }

    //     return () => {
    //         if (autoInterval) clearTimeout(autoInterval);
    //     };
    // }, [autoIncrement.current]);


    // useEffect(()=>{
    //  let diceTimeout;
    //  if(autoDiceRoll.current && myturn){
    //     diceTimeout = setTimeout(() => {
    //         onDiceClick();
    //     },30000);
    //  }
    //  else{
    //     if (diceTimeout) clearTimeout(diceTimeout);
    //  }
    //  return ()=>{
    //     if (diceTimeout) clearTimeout(diceTimeout);
    //  }
    // },[autoDiceRoll.current,myturn])

    const checkForKill = (player, piece) => {
        const currentPosition = currentPositions.current[player][piece];
        const opponents = ['P2', 'P3', 'P4'];
        let kill = false;
        opponents.forEach(opponent => {
            [0, 1, 2, 3].forEach(opponentPiece => {
                const opponentPosition = currentPositions.current[opponent][opponentPiece];
                if (currentPosition === opponentPosition && !SAFE_POSITIONS.includes(currentPosition)) {
                    setPiecePosition(opponent, opponentPiece, BASE_POSITIONS[opponent][opponentPiece]);
                    killAudio.play();
                    kill = true;
                }
            });
            return kill;
        })

    };

    const hasPlayerWon = (player) => {
        return [0, 1, 2, 3].every(piece => currentPositions.current[player][piece] === HOME_POSITIONS[player]);
    };

    const checkForEligiblePieces = (val) => {
        const user = localStorage.getItem('playerId')
        const player = 'P1';
        const eligiblePieces = getEligiblePieces(player);
        if (eligiblePieces.length < 1) {

            if (player === 'P1') {
                socket.emit("GameState", { gameId, currentPositions: currentPositions.current, turn: myturn ? 1 : 0, diceValue: diceValue.current, playerId: user, status: true });
                socket.emit('changeTurnClient', gameId);
            }
            setState(STATE.DICE_NOT_ROLLED);
        }
        else {
            setIsPeiceClicked(false);
        }
    };

    const getEligiblePieces = (player) => {
        return [0, 1, 2, 3].filter(piece => {
            const currentPosition = currentPositions.current[player][piece];
            if (currentPosition === HOME_POSITIONS[player]) return false;
            if (BASE_POSITIONS[player].includes(currentPosition) && diceValue.current !== 6) return false;
            if (HOME_ENTRANCE[player].includes(currentPosition) && diceValue.current > HOME_POSITIONS[player] - currentPosition) return false;
            return true;
        });
    };

    const handlePieceClick = (player, piece, e = null) => {

        // autoDiceRoll.current = false;
        if (isPeiceClicked || !getEligiblePieces(player).includes(piece)) {
            //console.log('mera turn nhi h ya fir peice already click ho chuki h ,', myturn, isPeiceClicked)
            return;
        }
        ////console.log('peice click thik ja rha h')
        if ((myturn && player == "P1") && getEligiblePieces(player).length > 0) {
            setIsPeiceClicked(true);
            //console.log('peice to shi h bhai')
            const user = localStorage.getItem("playerId");
            socket.emit("handleClickPiece", { gameId, player: user, piece, turn, id })
        }
        else {
            ////console.log('lagta h glt peice click kr di h')
        }

    };

    return (
        <>
            <div className="leftContainer leftContainer-Ludo">
                <div className={`ludomain ${result ? 'blur' : ''}`}>
                    <div className="sound"><img src="/image/images/soundon.png" alt="" srcset="" /></div>

                    <div className="ludo-container">
                        <div className="ludo Classic">
                            <div className="player-pieces">

                                {PLAYERS.map(player =>
                                    currentPositions.current[player].map((position, index) => (
                                        <PlayerPiece
                                            key={`${player}-${index}`}
                                            playerId={player}
                                            pieceId={index}
                                            position={position}
                                            onClick={handlePieceClick}
                                            game='Turnament'
                                        />

                                    ))
                                )}

                            </div>

                        </div>
                    </div>

                    <div className="footer">
                        <div className="footer-avtar">
                            <div className="img-left" style={{ position: 'relative' }}>
                                <div className={`P1 ${myturn && !result ? 'animation bj' : ''}`}
                                    key={random}
                                    style={{
                                        "--newvar": `${360 - (initialTime / 30) * 360}deg`,
                                        animationDuration: `${initialTime}s`, // Adjust duration dynamically
                                    }}
                                >
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnveTKwxve5MH7HXXKE-3iT7_ihOpC0CxLGg&s" alt="" srcset="" />
                                </div>
                                <div className='ludo_score'>Score:{count.points}</div>
                            </div>
                            {/*  <div className={`P2 ${!myturn && !result ? 'animation bj' : ''}`}
                                key={initialTime}
                                style={{
                                    "--newvar": `${360 - (initialTime / 30) * 360}deg`,
                                    animationDuration: `${initialTime}s`, // Adjust duration dynamically
                                }}
                            >
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnveTKwxve5MH7HXXKE-3iT7_ihOpC0CxLGg&s" alt="" srcset="" />
                            </div> */}
                        </div>
                        {myturn && !state.DICE_ROLLED ? <div className={`arrow ${state !== STATE.DICE_ROLLED ? 'shrink' : 'dis'}`}>
                            <img src="/image/images/arrow.png" alt="Arrow" />
                        </div> : ""}

                        <div className={`dice-main ${state === STATE.DICE_ROLLED ? 'disabled' : ''}`}>
                            {/* <img
                                src={`/image/images/Dice${diceValue.current}.png`}
                                className={`dice ${isSpinning ? 'spin' : ''} btn btn-dice`}
                                alt="This is zero"
                                onClick={state !== STATE.DICE_ROLLED ? onDiceClick : undefined}
                                id="dice-btn"
                            /> */}
                            <div className="dice-wrapper"
                                onClick={state !== STATE.DICE_ROLLED && myturn ? onDiceClick : undefined}
                            >
                                <div
                                    className={`dice ${isRolling ? "rolling" : ""}`}
                                    style={{
                                        transform: isRolling
                                            ? `rotateX(${Math.random() * 360}deg) rotateY(${Math.random() * 360}deg)`
                                            : finalTransform,
                                    }}
                                >
                                    <div className="face front">
                                        <img src="/image/images/Dice1.png" alt="1" />
                                    </div>
                                    <div className="face back">
                                        <img src="/image/images/Dice6.png" alt="6" />
                                    </div>
                                    <div className="face right1">
                                        <img src="/image/images/Dice4.png" alt="4" />
                                    </div>
                                    <div className="face left1">
                                        <img src="/image/images/Dice3.png" alt="3" />
                                    </div>
                                    <div className="face top">
                                        <img src="/image/images/Dice2.png" alt="2" />
                                    </div>
                                    <div className="face bottom">
                                        <img src="/image/images/Dice5.png" alt="5" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-last">
                            <div className="left">
                                <div className="left-upper">
                                    <div className="left-pieceimg">
                                        <img src="/image/images/P1.png" alt="" srcset="" />

                                    </div>
                                    <div className="coin-five">
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <div key={index} className="coin" style={{ backgroundColor: missedTurn >= index + 1 ? 'red' : 'green' }}></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="nameBox">
                                    <div className="name-heading">
                                        Name
                                    </div>
                                    <div className="Name">yte-ieh-idj</div>
                                </div>
                            </div>
                            <div className="right">

                                <div className="nameBox">
                                    <div className="name-heading">
                                        Moves Left
                                    </div>
                                    <div className="Name count">{count.count}</div>
                                </div>


                                {/* <div className="right-upper">
                                    <div className="left-pieceimg">
                                        <img src="/image/images/P1.png" alt="" srcset="" />
                                    </div>
                                    <div className="coin-five">
                                        <div className="coin"></div>
                                        <div className="coin"></div>
                                        <div className="coin"></div>
                                        <div className="coin"></div>
                                        <div className="coin"></div>
                                    </div>
                                </div> */}

                            </div>
                        </div>


                    </div>
                </div>
                {result ?
                    <div className='result'>
                        <div className='gameover'>
                            Game Over
                        </div>
                        {/* <div className='result_line'>
                            {result === 'Win' ? 'You Won !' : 'You Lost , Better luck next time !'}
                        </div> */}
                        <div>
                        </div>
                        {result === 'Win' ?
                            <img src='/image/images/win.gif' alt='Congratulations' />
                            : ''
                        }

                    </div>
                    : ''
                }

            </div>

        </>
    );
};

export default LudoBoard;
